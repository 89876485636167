import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { PolicyService, UsersService } from '@horizon/core';
import { User } from '@horizon/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuRoutePaths, RoutePaths } from '@shared/enums/routes.enums';
import { EventFireService } from '@shared/services/event-fire.service';
import { AuthorizationService } from './services/authorization.service';
import { SearchApiService } from './services/search.service';
import { layout } from './config/layout';
import { UtilsService } from '@horizon/core';
import { first, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { AppSectionsPermissionsKey } from '@shared/enums/permissions.enums';
import { TemplatesDataService } from '@shared/services/templates-data.service';
import {
  DialogNotification,
  NotificationService,
  ToastNotificationItem,
  ToastService,
} from '@horizon/core-notifications';
import { globalSettings } from 'src/global-settings';
import { HeaderConfigService } from './config/header-config.service';
import { SideBarMenuConfigService } from './config/side-bar-menu-config.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent implements OnInit {
  public isOnAcceptDisclaimer: boolean = false;
  public isOnContactAdmin: boolean = false;
  private readonly destroy$ = new Subject<void>();

  public loginShortDisplayName: string = undefined;
  public canUserSearch: boolean = true;
  public currentLanguage: string;
  public user: User;
  public userPhoto: SafeResourceUrl | null = null;

  public isIframe = window !== window.parent && !window.opener;

  public toastNotifications: ToastNotificationItem[] = [];
  public notificationsDialog: DialogNotification;
  public showLoadingSpinner: boolean = false;
  public dataRecentsSearch: any;
  public globalSettings: any = globalSettings;
  public urlContainsTabelau: boolean = false;
  //Menu permissions enums
  public menuRoutingPaths: any = MenuRoutePaths;
  public menuItemsPermissions: any = AppSectionsPermissionsKey;

  recentSearchContent = this.utils.getLayoutElementById(
    layout,
    'recent-search'
  );
  public templateRecentSearch: any = {
    id: 'template',
    fields: [
      {
        name: 'anchored',
        type: 'icon',
      },
      {
        name: 'account',
        type: 'url',
      },
      {
        name: 'text',
      },
      {
        name: 'date',
        type: 'date',
      },
    ],
  };

  constructor(
    private readonly router: Router,
    public headerSettingsService: HeaderConfigService,
    private authService: MsalService,
    private authorizationService: AuthorizationService,
    private translate: TranslateService,
    private policyService: PolicyService,
    private usersService: UsersService,
    private domSanitizer: DomSanitizer,
    private eventFireService: EventFireService,
    public menuSettingsService: SideBarMenuConfigService,
    private readonly toastService: ToastService,
    private readonly notificationService: NotificationService,
    private host: ElementRef<HTMLElement>,
    private readonly utils: UtilsService,
    private searchApiService: SearchApiService,
    private location: Location,
    private templatesDataService: TemplatesDataService,
    private sharedService: SharedService
  ) {
    this.menuSettingsService.setMenuColors(this.host);

    this.notificationService.getLoadingSpinner().subscribe((loading) => {
      this.showLoadingSpinner = loading;
    });

    this.toastService.getToastNotifications().subscribe((notifications) => {
      this.toastNotifications = notifications;
    });

    this.notificationService
      .getNotificationDialog()
      .subscribe((notifications) => {
        this.notificationsDialog = notifications;
      });
  }

  ngOnInit(): void {
    this.currentLanguage = this.translate.currentLang;
    this.setLoginDisplay();
    const currentUrl = this.location.path();
    this.urlContainsTabelau = currentUrl.includes('tableau');
    this.isOnAcceptDisclaimer = currentUrl.includes('accept-disclaimer');
    this.isOnContactAdmin = currentUrl.includes('contact-admin');
  }

  public languageChanged(language: string): void {
    this.changeLanguage(language);
  }

  private changeLanguage(language: string): void {
    this.eventFireService.currentLanguageChanged(language);
    this.currentLanguage = language;
  }

  public goHome(): void {
    const element = document.querySelector(
      '#sidebar-treeview > ul > li:nth-child(1)'
    ) as HTMLElement;
    element?.click();
    this.sharedService.setFirstMenuItemAsSelected();
    this.router.navigate([RoutePaths.HOME]);
  }

  public logout(): void {
    this.authorizationService.logout();
  }

  private setLoginDisplay(): void {
    const allAccounts = this.authService?.instance?.getAllAccounts();
    const localAccountId = allAccounts[0]?.localAccountId;

    this.fetchUser(localAccountId);
  }

  private fetchUser(userId): void {
    this.policyService.getMergedUser(userId).subscribe((res) => {
      this.user = res;
      this.changeLanguage(this.user.language ?? 'en');

      if(!res?.roleGuid || res?.roleGuid===""  || res?.roleGuid==undefined || res?.roleGuid==null){
        this.sharedService.setUserHasNoConfig()
      }

      if (res?.userPhoto) {
        this.userPhoto = this.domSanitizer.bypassSecurityTrustResourceUrl(
          'data:image;base64,' + res.userPhoto
        );
      } else {
        this.user.userPhoto = null;
        const allAccounts = this.authService?.instance?.getAllAccounts();
        const loginDisplay = allAccounts?.length > 0;

        if (loginDisplay && allAccounts[0]?.name) {
          const splittedName = allAccounts[0]?.name.split(' ');
          this.loginShortDisplayName = splittedName[0][0] + splittedName[1][0];
          this.usersService.init();
        }
      }
    });
  }

  public confirmModal(): void {
    this.notificationService.confirmActionClicked();
  }

  public totalData() {
    this.searchApiService
      .getAccountsSearch()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        response.map((item) => {
          const newDate = new Date(item.lastSearchDate);
          item['lastSearchDate'] = newDate.toLocaleString();
        });
        this.dataRecentsSearch = response;
      });
  }

  public updateData(args) {
    args.pin = !args.pin;
    this.searchApiService
      .postAccountSearch(args)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.totalData();
      });
  }

  public selectItemClick(id): void {
    const found = this.dataRecentsSearch.find((_) => _.accountNumber === id);
    if (found) {
      this.searchApiService
        .postAccountSearch(found)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.totalData();
          this.router.navigate([RoutePaths.ACCOUNT, id]).then(() => {
            this.templatesDataService.setTemplates({});
            this.templatesDataService.setTemplatesReferences({});
            this.templatesDataService.setItemsByTemplates({});
          });
        });
    } else {
      this.router.navigate([RoutePaths.ACCOUNT, id]).then(() => {
        this.templatesDataService.setTemplates({});
        this.templatesDataService.setTemplatesReferences({});
        this.templatesDataService.setItemsByTemplates({});
      });
    }
  }

  public onSearch(event: any): void {
    if (event.type === 'advanced') {
      this.router.navigate([RoutePaths.SEARCH]);
    } else if (event.value >= 0) {
      this.router.navigate([RoutePaths.SEARCH, event.value]);
    }
  }

  handleItemClick(data: any) {
    if (data.nodeId) this.sharedService.setSelectedMenuItem(data);
  }
}
