// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apim: window['env']['apim'],
  authority: window['env']['authority'],
  authScopes: window['env']['authScopes'],
  clientid: window['env']['clientid'],
  envTag: window['env']['envTag'],
  production: window['env']['production'],
  redirecturl: window['env']['redirecturl'],
  userRefreshIntervalInSeconds: window['env']['userRefreshIntervalInSeconds'],
  tableauServer: window['env']['tableauServer'],
  blobStorage: window['env']['blobStorage'],
  environment: window['env']['environment'],
  hubspoturl: window['env']['hubspoturl'],
};
